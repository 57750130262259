import React, { Fragment, useEffect, useState } from 'react';
import {
  Grid,
  TextField,
  Autocomplete,
  Typography,
  Box,
  MenuItem,
  Divider,
  Tooltip,
  IconButton,
  Chip,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { COLORS } from '../../utils/colors';
import { LoadingButton } from '../LoadingButton/LoadingButton';
import { getEmploymentLiaisonList, getUserInfoById } from '../../services/user.service';
import { ClientStatus } from '../../utils/clientStatus';
import {
  IClientEmplInfo,
  assignEmploymentLiaison,
  markClientReadyForEmployment,
  markClientsWorkshopCompleted,
  setWorkshopToClient,
  updateClientsEmplInfo,
} from '../../services/client.service';
import { AccountCircleOutlined, CancelOutlined, EditOutlined, OpenInNewOutlined } from '@mui/icons-material';
import { toast } from 'react-toastify';
import EmploymentIntakeForm from './EmploymentIntakeForm';
import { fetchAllWorkshops, getWorkshopById } from '../../services/workshop.service';
import dayjs from 'dayjs';
import { UserModel } from '../../store/user/userModel';
import { IClientJRTComplete, IWorkshopInfo } from '../../store/employmentModule/workshopModal';
import { fetchAllJobs, fetchJobsWithClientInterviews, getJobById } from '../../services/employmentModule.service';
import { IJobInfo } from '../../store/employmentModule/employmentModal';
import WorkshopBasicInfo from '../WorkshopBasicInfo/WorkshopBasicInfo';
import { useNavigate } from 'react-router-dom';
import { Palette } from '../../utils/palette';
import { useAppSelector } from '../../store/store';
import { shouldShowButton } from '../../pages/admin/dashboard/dashboardAccessControl';
import ClientScheduledInterviewDetails from '../Modals/ClientScheduledInterviewDetails/ClientScheduledInterviewDetails';
import { employmentStatusOptions, employmentTypes } from '../../utils/jobTypes';
import ScheduleClientJobInterview from './ScheduleClientJobInterview';
import ClientsScheduledInterviews, { IClientScheduledJob } from './ClientsScheduledInterviews';
import { jobOfferStatusEnums } from './EmploymentEnum';
import PreHireRequirements from './PreHireRequirements';

interface ClientEmploymentProps {
  clientInfo: any;
}

const ClientEmployment = (props: ClientEmploymentProps) => {
  const { clientInfo } = props;
  const user: UserModel = useAppSelector((state: any) => state.user.user);
  const navigate = useNavigate();

  const [selectedLiaison, setSelectedLiaison] = useState<any>(null);
  const [selectedWorkshop, setSelectedWorkshop] = useState<string | null>(null);
  const [liaisonOptions, setLiaisonOptions] = useState<UserModel[]>([]);
  const [workshopOptions, setWorkshopOptions] = useState<IWorkshopInfo[]>([]);
  const [clientJobData, setClientJobData] = useState<IJobInfo[]>([]);
  const [jobList, setJobList] = useState<IJobInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [employmentReadyLoading, setEmploymentReadyLoading] = useState<boolean>(false);
  const [updateEmplInfoLoading, setUpdateEmplInfoLoading] = useState<boolean>(false);
  const [completeWorkshopLoader, setCompleteWorkshopLoader] = useState<boolean>(false);
  const [fetchClientJobLoading, setFetchClientJobLoading] = useState<boolean>(false);
  const [showScheduledInterviewDetails, setShowScheduledInterviewDetails] = useState<boolean>(false);
  const [showEmploymentLiaison, setShowEmploymentLiaison] = useState<boolean>(
    clientInfo.employmentLiaisonId ? false : true
  );
  const [showClientEmplInfo, setShowClientEmplInfo] = useState<boolean>(clientInfo.employmentType ? true : false);
  const [showEnrollWorkshopForm, setShowEnrollWorkshopForm] = useState<boolean>(clientInfo.workshopId ? false : true);
  const [liaisonData, setLiaisonData] = useState<any>(null);
  const [workshopData, setWorkshopData] = useState<any>(null);
  const [employmentData, setEmploymentData] = useState<any>(null);
  const [selectedEmplType, setSelectedEmplType] = useState<{ id: number; label: string }>({ id: 0, label: '' });
  const [selectedEmplStatus, setSelectedEmplStatus] = useState<{ id: number; label: string }>({ id: 0, label: '' });
  const [otherEmplType, setOtherEmplType] = useState<string>('');
  const [fetchJobData, setFetchJobData] = useState<boolean>(false);

  const tooltipDesc =
    // eslint-disable-next-line max-len
    'Client sent to employment are made visible in the Portal’s “Employment” module. Clients that have been converted past “Prospective” status and have not been suspended may be sent to the employment team by any staff member once they have been evaluated to be ready for employment services.';

  const showLiaisonForm: boolean =
    clientInfo.status !== ClientStatus.PROSPECTIVE &&
    clientInfo.status !== ClientStatus.SUSPENDED &&
    clientInfo.status !== ClientStatus.DROP_OUT;

  const reFetchJobData = () => {
    setFetchJobData(!fetchJobData);
  };
  const handleLiaisonChange = (event: any, newValue: any) => {
    setSelectedLiaison(newValue);
  };

  const handleButtonClick = async () => {
    if (selectedLiaison) {
      setLoading(true);
      try {
        const res = await assignEmploymentLiaison(clientInfo.id, selectedLiaison.id);
        if (res) {
          toast.success('Employment Liaison Asssigned Successfully!');
          await getLiaisonDetail(selectedLiaison.id);
        }
      } catch (err) {
        // catch error
      }
      setLoading(false);
      getWorkshopList();
      setSelectedLiaison(null);
      setShowEmploymentLiaison(false);
    }
  };

  const handleEnrollment = async () => {
    if (selectedWorkshop) {
      setLoading(true);
      try {
        const res = await setWorkshopToClient(clientInfo.id, selectedWorkshop);
        if (res) {
          toast.success('Client Enrolled In Workshop Successfully !');
          await getWorkshopDetail(selectedWorkshop);
        }
      } catch (err) {
        // catch error
      }
      setLoading(false);
      setShowEnrollWorkshopForm(false);
      setSelectedWorkshop(null);
    }
  };

  const getWorkshopList = async () => {
    try {
      const unsubscribe = await fetchAllWorkshops((workshopList: any) => {
        if (workshopList && workshopList?.length) {
          const updatedList = workshopList.map((item: any) => {
            return {
              ...item,
              dateTime: item.dateTime[0].timing,
            };
          });
          const sortedValues = [...updatedList]
            .filter((obj) => new Date(obj.dateTime) >= new Date())
            .sort((a, b) => {
              const dateA = new Date(a.dateTime);
              const dateB = new Date(b.dateTime);
              return dateA.getTime() - dateB.getTime();
            });
          setWorkshopOptions(sortedValues);
        }
      });
      return () => {
        unsubscribe();
      };
    } catch (err) {
      // catch error
    }
  };

  const getLiaisonList = async () => {
    try {
      const unsubscribe: any = await getEmploymentLiaisonList((data: any) => {
        setLiaisonOptions(data);
      });
      return () => {
        unsubscribe();
      };
    } catch (err) {
      // catch error
    }
  };

  const fetchClientJoblist = async () => {
    try {
      setFetchClientJobLoading(true);
      const unsubscribe = await fetchJobsWithClientInterviews(clientInfo.id, (jobData: any) => {
        setClientJobData(jobData);
        setFetchClientJobLoading(false);
      });
      return () => {
        unsubscribe();
      };
    } catch (err) {
      // catch error
    }
  };

  const fetchJoblist = async () => {
    try {
      const unsubscribe = await fetchAllJobs(clientInfo.id, (jobData: any) => {
        setJobList(jobData);
      });
      return () => {
        unsubscribe();
      };
    } catch (err) {
      // catch error
    }
  };

  const getLiaisonDetail = async (employmentLiaisonId: string) => {
    try {
      const data = await getUserInfoById(employmentLiaisonId);
      setLiaisonData(data);
    } catch (err) {
      // catch error
    }
  };

  const getWorkshopDetail = async (workshopId: string) => {
    try {
      const unsubscribe = await getWorkshopById(workshopId, (data: any) => {
        setWorkshopData(data);
      });
      return () => {
        unsubscribe();
      };
    } catch (err) {
      // catch error
    }
  };

  const getEmploymentDetail = async (jobId: string) => {
    try {
      const unsubscribe = await getJobById(jobId, (data: any) => {
        setEmploymentData(data);
      });
      return () => {
        unsubscribe();
      };
    } catch (err) {
      // catch error
    }
  };

  useEffect(() => {
    try {
      getLiaisonList();
      if (clientInfo.employmentLiaisonId) {
        getLiaisonDetail(clientInfo.employmentLiaisonId);
        getWorkshopList();
      }
      if (clientInfo?.workshopId) {
        getWorkshopDetail(clientInfo.workshopId);
      }
      if (clientInfo?.employment?.jobId) {
        getEmploymentDetail(clientInfo.employment.jobId);
      }
    } catch (err) {
      // catch error
    }
    // eslint-disable-next-line
  }, [clientInfo]);

  useEffect(() => {
    try {
      // if (clientInfo.scheduledJobInterview) {
      // }
      fetchClientJoblist();
    } catch (err) {
      // catch error
    }
    // eslint-disable-next-line
  }, [fetchJobData]);

  useEffect(() => {
    try {
      fetchJoblist();
    } catch (err) {
      // catch error
    }
    // eslint-disable-next-line
  }, [fetchJobData]);

  const handleMarkWorkshopComplete = async () => {
    if (clientInfo.workshopId) {
      setCompleteWorkshopLoader(true);
      try {
        const payload: IClientJRTComplete = {
          id: clientInfo.id,
          workshopId: clientInfo.workshopId,
          markedAt: dayjs().toISOString(),
        };
        await markClientsWorkshopCompleted(payload);
        setCompleteWorkshopLoader(false);
      } catch (err) {
        // catch error
      }
    }
  };
  const handleMarkEmploymentReadiness = async () => {
    setEmploymentReadyLoading(true);
    try {
      await markClientReadyForEmployment(clientInfo.id);
      setEmploymentReadyLoading(false);
    } catch (err) {
      // catch error
    }
  };

  const handleUpdateEmplInfo = async () => {
    setUpdateEmplInfoLoading(true);
    const payload: IClientEmplInfo = {
      id: clientInfo?.id,
      employmentModuleStatus: selectedEmplStatus.label,
      employmentType: selectedEmplType.id === 5 ? otherEmplType : selectedEmplType.label,
    };
    try {
      await updateClientsEmplInfo(payload);
      setOtherEmplType('');
      setSelectedEmplStatus({ id: 0, label: '' });
      setSelectedEmplType({ id: 0, label: '' });
      setShowClientEmplInfo(true);
      setUpdateEmplInfoLoading(false);
    } catch (err) {
      // catch error
      setUpdateEmplInfoLoading(false);
    }
  };

  const isWorkshopCompleted =
    clientInfo?.completedWorkshops?.some((item: any) => item.workshopId === clientInfo?.workshopId) || false;
  const isOfferAccepted = clientInfo?.scheduledJobInterview?.some(
    (item: IClientScheduledJob) => item.offerStatus === jobOfferStatusEnums.OFFER_ACCEPTED
  );
  return (
    <Grid container spacing={2} columns={12}>
      <Grid item lg={4}>
        <Tooltip
          title={
            <Fragment>
              <Typography sx={{ textAlign: 'justify', fontSize: 10 }}>{tooltipDesc}</Typography>
            </Fragment>
          }
          arrow
          placement='top'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: '#edf5f4',
                color: '#000',
                borderRadius: '8px',
                border: '2px solid black',
                padding: '10px',
              },
            },
            arrow: {
              sx: {
                color: '#edf5f4',
                '&::before': {
                  border: '2px solid black',
                },
              },
            },
          }}>
          <span>
            <LoadingButton
              color='primary'
              label='Send to Employment'
              variant='contained'
              loading={employmentReadyLoading}
              disabled={clientInfo.employmentReadiness || !shouldShowButton('markClientReadyForEmployment', user?.role)}
              onClick={handleMarkEmploymentReadiness}
              styles={{
                textTransform: 'none',
                my: 2,
                width: '100%',
              }}
            />
          </span>
        </Tooltip>
        <Box
          sx={{ background: COLORS.card.cardBg }}
          borderRadius={'10px'}
          p={2}
          display={
            showLiaisonForm &&
            showEmploymentLiaison &&
            shouldShowButton('assignEmploymentLiaison', user.role) &&
            clientInfo.employmentReadiness
              ? 'block'
              : 'none'
          }>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant='h6'>Assign Employment Liaison To Client</Typography>
            <IconButton
              color='primary'
              disabled={!clientInfo.employmentLiaisonId}
              onClick={() => {
                setShowEmploymentLiaison(false);
              }}>
              <CancelOutlined />
            </IconButton>
          </Box>

          <Box py={2}>
            <Divider variant='fullWidth' light={true} />
          </Box>

          <Grid item xs={12}>
            <Autocomplete
              value={selectedLiaison}
              size='small'
              onChange={handleLiaisonChange}
              options={liaisonOptions.map((option: any) => ({
                id: option.id,
                label: `${option.firstName} ${option.lastName}  ${option.numClients ? `(${option.numClients})` : ''}`,
              }))}
              getOptionLabel={(option: any) => option.label}
              renderInput={(params) => <TextField {...params} label='Employment Liaison' />}
            />

            <Box display='flex' justifyContent='center' mt={2}>
              <LoadingButton
                onClick={handleButtonClick}
                label='Set Employment Liaison'
                styles={{
                  textTransform: 'none',
                }}
                loading={loading}
                disabled={!selectedLiaison}
                size='medium'
                type='submit'
                variant='contained'
              />
            </Box>
          </Grid>
        </Box>

        <Box
          sx={{ background: COLORS.card.cardBg }}
          borderRadius={'10px'}
          p={2}
          display={!showEmploymentLiaison && clientInfo.employmentLiaisonId ? 'block' : 'none'}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant='h6'>Assigned Employment Liaison</Typography>

            <Tooltip title='Update Employment Liaison' arrow>
              <IconButton
                color='primary'
                disabled={!shouldShowButton('assignEmploymentLiaison', user?.role)}
                onClick={() => {
                  setShowEmploymentLiaison(true);
                }}>
                <EditOutlined />
              </IconButton>
            </Tooltip>
          </Box>

          <Box py={2}>
            <Divider variant='fullWidth' light={true} />
          </Box>

          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <AccountCircleOutlined fontSize='large' />
            </Grid>

            <Grid item>
              <Typography variant='body1'>
                {liaisonData?.firstName} {liaisonData?.lastName}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {shouldShowButton('updateEmploymentInfo', user?.role) ? (
          <Box
            sx={{ background: COLORS.card.cardBg }}
            borderRadius={'10px'}
            p={2}
            my={2}
            display={!showClientEmplInfo && clientInfo.employmentLiaisonId ? 'block' : 'none'}>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography variant='h6'>Update Client Employment Information</Typography>
              <IconButton
                color='primary'
                disabled={!clientInfo.employmentType || !shouldShowButton('updateEmploymentInfo', user?.role)}
                onClick={() => {
                  setShowClientEmplInfo(true);
                }}>
                <CancelOutlined />
              </IconButton>
            </Box>
            <Box py={2}>
              <Divider variant='fullWidth' light={true} />
            </Box>

            <Grid item xs={12} spacing={2}>
              <Box sx={{ my: 1 }}>
                <Autocomplete
                  value={selectedEmplType}
                  disableClearable
                  size='small'
                  onChange={(event: any, newValue: any) => {
                    setSelectedEmplType(newValue);
                  }}
                  options={employmentTypes.map((option: any) => ({
                    id: option.id,
                    label: option.title,
                  }))}
                  getOptionLabel={(option: any) => option.label}
                  renderInput={(params) => <TextField {...params} label='Employment Type' />}
                />
              </Box>
              {selectedEmplType.id === 5 ? (
                <Box sx={{ my: 2 }}>
                  <TextField
                    fullWidth
                    size='small'
                    placeholder='Other (please specify)'
                    value={otherEmplType}
                    onChange={(event) => {
                      setOtherEmplType(event.target.value);
                    }}
                  />
                </Box>
              ) : null}
              <Box sx={{ my: 1 }}>
                <Autocomplete
                  value={selectedEmplStatus}
                  disableClearable
                  size='small'
                  onChange={(event: any, newValue: any) => {
                    setSelectedEmplStatus(newValue);
                  }}
                  options={employmentStatusOptions.map((option: any) => ({
                    id: option.id,
                    label: option.title,
                  }))}
                  getOptionLabel={(option: any) => option.label}
                  renderInput={(params) => <TextField {...params} label='Employment Status' />}
                />
              </Box>

              <Box display='flex' justifyContent='center' mt={2}>
                <LoadingButton
                  onClick={handleUpdateEmplInfo}
                  label='Submit'
                  styles={{
                    textTransform: 'none',
                  }}
                  loading={updateEmplInfoLoading}
                  disabled={
                    !selectedEmplStatus.label || (selectedEmplType.id === 5 ? !otherEmplType : !selectedEmplType.label)
                  }
                  size='medium'
                  type='submit'
                  variant='contained'
                />
              </Box>
            </Grid>
          </Box>
        ) : null}
        <Box
          sx={{ background: COLORS.card.cardBg }}
          borderRadius={'10px'}
          p={2}
          my={2}
          display={showClientEmplInfo && clientInfo.employmentLiaisonId ? 'block' : 'none'}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant='h6'>Client Employment Information</Typography>
            <Tooltip title='Update Client Employment Info' arrow>
              <IconButton
                color='primary'
                disabled={!shouldShowButton('updateEmploymentInfo', user?.role)}
                onClick={() => {
                  setShowClientEmplInfo(false);
                }}>
                <EditOutlined />
              </IconButton>
            </Tooltip>
          </Box>
          <Box py={2}>
            <Divider variant='fullWidth' light={true} />
          </Box>
          <List>
            <ListItem disablePadding>
              <ListItemText
                primary={'Employment Type:'}
                secondary={clientInfo?.employmentType || ''}
                primaryTypographyProps={styles.listItemPrimaryText}
                sx={styles.listItemContainer}
              />
            </ListItem>

            <ListItem disablePadding>
              <ListItemText
                primary={'Employment Status:'}
                secondary={clientInfo?.employmentModuleStatus || ''}
                primaryTypographyProps={styles.listItemPrimaryText}
                sx={styles.listItemContainer}
              />
            </ListItem>
          </List>
        </Box>
        {shouldShowButton('updateEmploymentInfo', user?.role) ? (
          <Box>
            <ScheduleClientJobInterview
              showLiaisonForm={showLiaisonForm}
              clientInfo={clientInfo}
              user={user}
              fetchJobData={reFetchJobData}
              jobList={jobList}
            />
          </Box>
        ) : null}
        <Box
          sx={{ background: COLORS.card.cardBg }}
          borderRadius={'10px'}
          p={2}
          my={2}
          display={showLiaisonForm && clientInfo.employment ? 'block' : 'none'}>
          <Typography variant='h6'>Client Employment Details</Typography>

          <Box py={2}>
            <Divider variant='fullWidth' light={true} />
          </Box>

          <Grid container spacing={2} alignItems='center' justifyContent={'space-between'}>
            <Grid item>
              <Typography variant='body1'>{employmentData?.title}</Typography>
              <Typography variant='body1'>
                {dayjs(clientInfo?.employment?.startAt).tz('America/New_York').format('MM/DD/YYYY, hh:mm A')}
              </Typography>
            </Grid>

            <Grid item>
              <Tooltip title='View Job Info' arrow>
                <IconButton
                  color='primary'
                  disabled={!shouldShowButton('updateEmploymentInfo', user?.role)}
                  onClick={() => {
                    navigate('../job-info', {
                      state: { jobId: clientInfo?.employment?.jobId },
                    });
                  }}>
                  <OpenInNewOutlined style={{ color: COLORS.theme.primaryColor }} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid item lg={4}>
        {shouldShowButton('EnrollInWorkshop', user?.role) ? (
          <Box
            sx={{ background: COLORS.card.cardBg }}
            borderRadius={'10px'}
            p={2}
            display={showLiaisonForm && clientInfo.employmentLiaisonId && showEnrollWorkshopForm ? 'block' : 'none'}>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography variant='h6'>Enroll Client In a Workshop</Typography>
              <IconButton
                color='primary'
                disabled={!clientInfo.workshopId}
                onClick={() => {
                  setShowEnrollWorkshopForm(false);
                }}>
                <CancelOutlined />
              </IconButton>
            </Box>
            <Box py={2}>
              <Divider variant='fullWidth' light={true} />
            </Box>

            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                label='Schedule a Workshop'
                size='small'
                name='workshopId'
                value={selectedWorkshop}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  const newValue = event.target.value as string;
                  setSelectedWorkshop(newValue);
                }}>
                {workshopOptions.map((item: any) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.title} {' | '}
                    {item.type} {' | '}
                    {item.location} {' | '}
                    {dayjs(item.dateTime).tz('America/New_York').format('MM/DD/YYYY, hh:mm A')} {' | '}
                  </MenuItem>
                ))}
              </TextField>

              <Box display='flex' justifyContent='center' mt={2}>
                <LoadingButton
                  onClick={handleEnrollment}
                  label='Enroll in Workshop'
                  styles={{
                    textTransform: 'none',
                  }}
                  loading={loading}
                  disabled={!selectedWorkshop}
                  size='medium'
                  type='submit'
                  variant='contained'
                />
              </Box>
            </Grid>
          </Box>
        ) : null}
        <Box
          sx={{ background: COLORS.card.cardBg }}
          borderRadius={'10px'}
          p={2}
          display={workshopData && clientInfo?.workshopId && !showEnrollWorkshopForm ? 'block' : 'none'}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant='h6'>Assigned Workshop Details</Typography>
              <Box>
                {isWorkshopCompleted ? (
                  <Chip
                    size='small'
                    sx={{
                      background: Palette.green_E4F3E5,
                      color: Palette.green_4CAF50,
                    }}
                    label={
                      <Typography
                        variant='button'
                        sx={{
                          fontSize: '10px',
                          textTransform: 'Capitalize',
                          lineHeight: 1.5,
                        }}>
                        Completed
                      </Typography>
                    }
                  />
                ) : null}

                <Tooltip title='Update Workshop' arrow>
                  <IconButton
                    disabled={!shouldShowButton('EnrollInWorkshop', user?.role)}
                    color='primary'
                    onClick={() => {
                      setShowEnrollWorkshopForm(true);
                    }}>
                    <EditOutlined style={{ color: COLORS.theme.primaryColor }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Box py={2}>
              <Divider variant='fullWidth' light={true} />
            </Box>

            <Grid container alignItems='center'>
              <Grid item>
                {workshopData && (
                  <WorkshopBasicInfo
                    workshop={workshopData}
                    clientWorkshopAttendance={clientInfo.workshopAttendance ? clientInfo.workshopAttendance : []}
                  />
                )}
              </Grid>
            </Grid>
            <Box py={2}>
              <Divider variant='fullWidth' light={true} />
            </Box>
            <LoadingButton
              color='primary'
              label='Mark Workshop Completed'
              variant='contained'
              loading={completeWorkshopLoader}
              disabled={
                !clientInfo.workshopAttendance ||
                isWorkshopCompleted ||
                !shouldShowButton('EnrollInWorkshop', user?.role)
              }
              onClick={handleMarkWorkshopComplete}
              styles={{
                textTransform: 'none',
                mt: { xs: 2, md: 0 },
                width: '100%',
              }}
            />
          </Grid>
        </Box>

        <Box
          sx={{ mt: 2 }}
          display={showLiaisonForm && clientInfo.scheduledJobInterview && !clientInfo.employment ? 'block' : 'none'}>
          <ClientsScheduledInterviews
            clientInfo={clientInfo}
            clientJobData={clientJobData}
            loading={fetchClientJobLoading}
            fetchJobData={reFetchJobData}
            user={user}
          />
        </Box>
      </Grid>

      <Grid item lg={4}>
        <Box mb={2} display={clientInfo.employmentLiaisonId ? 'block' : 'none'}>
          <EmploymentIntakeForm clientId={clientInfo.id} user={user} />
        </Box>
        <Box mt={2} display={isOfferAccepted && !clientInfo.employment ? 'block' : 'none'}>
          <PreHireRequirements clientInfo={clientInfo} user={user} />
        </Box>
      </Grid>
      <ClientScheduledInterviewDetails
        open={showScheduledInterviewDetails}
        onClose={() => setShowScheduledInterviewDetails(false)}
        clientInfo={clientInfo}
      />
    </Grid>
  );
};

export default ClientEmployment;

const styles = {
  listItemContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  listItemPrimaryText: {
    fontWeight: 'bold',
    paddingRight: 1,
    width: '40%',
    alignSelf: 'flex-start',
    display: 'block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
};
