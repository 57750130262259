import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { COLORS } from '../../../utils/colors';
import CloseIcon from '@mui/icons-material/Close';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker, renderTimeViewClock } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { FieldArray, Formik, type FormikHelpers } from 'formik';
import { UserModel } from '../../../store/user/userModel';
import { getEmploymentLiaisonList } from '../../../services/user.service';
import { fetchAllWorkshops } from '../../../services/workshop.service';
import { IWorkshopInfo } from '../../../store/employmentModule/workshopModal';
import { IJobInfo } from '../../../store/employmentModule/employmentModal';
import { getJobOpportinities } from '../../../services/employmentModule.service';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { employmentStatusOptions, employmentTypes } from '../../../utils/jobTypes';
import { LoadingButton } from '../../LoadingButton/LoadingButton';
import { backEmploymentData } from '../../../services/backfillClientsData.service';

interface IBackFillEmpDataProps {
  open: boolean;
  onClose: () => void;
  clientInfo: any;
  refreshData: Function;
}

interface IScheduledInterview {
  jobId: string;
  interviewScheduledAt: string;
  jobTitle?: string;
}

export interface IBackFillEmplData {
  id?: string;
  sentInEmploymentAt: string;
  employmentLiaisonId: string;
  liaisonAssignedAt: string;
  intakeCompletedAt: string;
  workshopId: string;
  enrolledInWorkshopAt: string;
  workshopAttendance: string;
  attendanceMarkedAt: string;
  workshopMarkedCompletedAt: string;
  scheduledJobInterviews: IScheduledInterview[];
  confirmedJobId: string;
  employmentConfirmedAt: string;
  employmentModuleStatus: string;
  employmentType: string;
}

interface ISelectedJob {
  jobId: string;
  jobTitle: string;
  interviewScheduledAt?: string;
}

interface ISelectedTypes {
  id: string | number;
  label: string;
}

const BackFillEmploymentData = (props: IBackFillEmpDataProps) => {
  const { open, onClose, clientInfo, refreshData } = props;
  const [empLiaisonList, setEmpLiaisonList] = useState<UserModel[]>([]);
  const [workshopList, setWorkshopList] = useState<IWorkshopInfo[]>([]);
  const [jobList, setJobList] = useState<IJobInfo[]>([]);
  const [selectedJobs, setSelectedJobs] = useState<ISelectedJob[]>([]);
  const [selectedEmpLiaison, setSelectedEmpLiaison] = useState<ISelectedTypes>({ id: '', label: '' });
  const [selectedWorkshop, setSelectedWorkshop] = useState<ISelectedTypes>({ id: '', label: '' });
  const [selectedConfirmedJob, setSelectedConfirmedJob] = useState<ISelectedTypes>({ id: '', label: '' });
  const [selectedEmplStatus, setSelectedEmplStatus] = useState<ISelectedTypes>({ id: '', label: '' });
  const [selectedEmplType, setSelectedEmplType] = useState<ISelectedTypes>({ id: 0, label: '' });
  const [otherEmplType, setOtherEmplType] = useState<string>('');

  const initialValues: IBackFillEmplData = {
    sentInEmploymentAt: '',
    employmentLiaisonId: '',
    liaisonAssignedAt: '',
    intakeCompletedAt: '',
    workshopId: '',
    enrolledInWorkshopAt: '',
    workshopAttendance: '',
    attendanceMarkedAt: '',
    workshopMarkedCompletedAt: '',
    scheduledJobInterviews: [],
    confirmedJobId: '',
    employmentConfirmedAt: '',
    employmentModuleStatus: '',
    employmentType: '',
  };

  useEffect(() => {
    (async () => {
      const unsubscribe: any = await getEmploymentLiaisonList((data: any) => {
        setEmpLiaisonList(data);
      });
      return () => {
        unsubscribe();
      };
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const jobData = await getJobOpportinities();
      setJobList(jobData);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const unsubscribe = await fetchAllWorkshops((workshopList: any) => {
        if (workshopList && workshopList?.length) {
          const updatedList = workshopList.map((item: any) => {
            return {
              ...item,
              dateTime: item.dateTime[0].timing,
            };
          });
          const sortedValues = [...updatedList].sort((a, b) => {
            const dateA = new Date(a.dateTime);
            const dateB = new Date(b.dateTime);
            return dateA.getTime() - dateB.getTime();
          });
          setWorkshopList(sortedValues);
        }
      });
      return () => {
        unsubscribe();
      };
    })();
  }, []);

  const renderDateTimePicker = (label: string, name: string, value: any, setFieldValue: any, fieldName: string) => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} localeText={{ okButtonLabel: 'Select' }}>
        <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
          <DateTimePicker
            label={label}
            slotProps={{
              textField: { size: 'small', error: false, fullWidth: true },
            }}
            name={name}
            value={value}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
              seconds: renderTimeViewClock,
            }}
            onChange={(date: string | number | Date | dayjs.Dayjs | null | undefined) => {
              const formattedDate = dayjs(date)?.toISOString() || '';
              setFieldValue(fieldName, formattedDate);
            }}
          />
        </DemoContainer>
      </LocalizationProvider>
    );
  };

  const handleCheckboxChange = (jobId: string, jobTitle: string) => {
    if (selectedJobs.some((item) => item.jobId === jobId)) {
      setSelectedJobs(selectedJobs.filter((item: ISelectedJob) => item.jobId !== jobId));
    } else {
      setSelectedJobs([...selectedJobs, { jobTitle, jobId }]);
    }
  };

  const handleSubmit = async (values: IBackFillEmplData, { setSubmitting }: FormikHelpers<IBackFillEmplData>) => {
    try {
      const payload = { ...values, id: clientInfo.id };
      await backEmploymentData(payload);
    } catch (error) {
      console.error(error);
    }
    setSubmitting(false);
    refreshData();
    handleClose();
  };

  const handleClose = () => {
    onClose();
    setSelectedEmpLiaison({ id: '', label: '' });
    setSelectedWorkshop({ id: '', label: '' });
    setSelectedJobs([]);
    setOtherEmplType('');
    setSelectedEmplType({ id: '', label: '' });
    setSelectedEmplStatus({ id: '', label: '' });
    setSelectedConfirmedJob({ id: '', label: '' });
  };

  return (
    <>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { borderRadius: '14px', width: '60%', maxWidth: 'none' } }}>
        <DialogTitle height={70} borderBottom={`1px solid ${COLORS.palette.borderColor}`}>
          <Grid container direction='row' justifyContent='space-between' alignItems='center' marginBottom='20px'>
            <Typography variant='h6'>{`Backfill Client's Employment Data`}</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Formik initialValues={initialValues} onSubmit={handleSubmit} isInitialValid={false}>
            {({ values, setFieldValue, isSubmitting, handleSubmit }) => {
              return (
                <form>
                  <Grid container>
                    <Grid
                      item
                      sx={{
                        width: '100%',
                        mt: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}>
                      <Typography mr={3} fontWeight={'bold'}>
                        Client Intake Completed At :
                      </Typography>
                      <Grid lg={6} md={12} xs={12} mr={1}>
                        {renderDateTimePicker(
                          'Select Date and Time',
                          'intakeCompletedAt',
                          values.intakeCompletedAt ? dayjs(values.intakeCompletedAt) : null,
                          setFieldValue,
                          'intakeCompletedAt'
                        )}
                      </Grid>
                    </Grid>
                    {!clientInfo.employmentLiaisonId ? (
                      <>
                        <Grid
                          item
                          sx={{
                            width: '100%',
                            mt: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}>
                          <Typography mr={3} fontWeight={'bold'}>
                            Sent to Employment Module At :
                          </Typography>
                          <Grid lg={6} md={12} xs={12} mr={1}>
                            {renderDateTimePicker(
                              'Select Date and Time',
                              'sentInEmploymentAt',
                              values.sentInEmploymentAt ? dayjs(values.sentInEmploymentAt) : null,
                              setFieldValue,
                              'sentInEmploymentAt'
                            )}
                          </Grid>
                        </Grid>
                        <Typography fontWeight={'bold'} sx={{ mt: 2 }}>
                          Assign Employment Liaison :
                        </Typography>
                        <Grid container display={'flex'} justifyContent={'space-between'}>
                          <Grid lg={6} xs={12} sx={{ pr: 1, mt: 1 }}>
                            <Autocomplete
                              fullWidth
                              size='small'
                              disableClearable
                              value={selectedEmpLiaison}
                              options={empLiaisonList.map((item: any) => ({
                                label: item.firstName + ' ' + item.lastName,
                                id: item.id,
                              }))}
                              getOptionLabel={(option: any) => option.label}
                              onChange={(event, object: any) => {
                                setFieldValue('employmentLiaisonId', object.id);
                                setSelectedEmpLiaison(object);
                              }}
                              renderInput={(params) => <TextField {...params} label='Select Employment Liaison' />}
                              isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                            />
                          </Grid>
                          <Grid lg={6} md={12} xs={12} pr={1}>
                            {renderDateTimePicker(
                              'Assigned At',
                              'liaisonAssignedAt',
                              values.liaisonAssignedAt ? dayjs(values.liaisonAssignedAt) : null,
                              setFieldValue,
                              'liaisonAssignedAt'
                            )}
                          </Grid>
                        </Grid>
                      </>
                    ) : null}
                    <Typography fontWeight={'bold'} sx={{ mt: 2 }}>
                      Enroll in a Workshop :
                    </Typography>
                    <Grid container display={'flex'} justifyContent={'space-between'}>
                      <Grid lg={6} xs={12} sx={{ pr: 1, mt: 1 }}>
                        <Autocomplete
                          fullWidth
                          size='small'
                          disableClearable
                          value={selectedWorkshop}
                          options={workshopList.map((item: any) => ({
                            label: `${item.title} | ${item.type} | ${item.location} | ${dayjs(item.dateTime)
                              .tz('America/New_York')
                              .format('MM/DD/YYYY, hh:mm A')}`,
                            id: item.id,
                          }))}
                          getOptionLabel={(option: any) => option.label}
                          onChange={(event, object: any) => {
                            setFieldValue('workshopId', object.id);
                            setSelectedWorkshop(object);
                          }}
                          renderInput={(params) => <TextField {...params} label='Select a Workshop' />}
                          isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                        />
                      </Grid>
                      <Grid lg={6} md={12} xs={12} pr={1}>
                        {renderDateTimePicker(
                          'Enrolled At',
                          'enrolledInWorkshopAt',
                          values.enrolledInWorkshopAt ? dayjs(values.enrolledInWorkshopAt) : null,
                          setFieldValue,
                          'enrolledInWorkshopAt'
                        )}
                      </Grid>
                    </Grid>
                    <Typography fontWeight={'bold'} sx={{ mt: 2 }}>
                      Workshop Attendance :
                    </Typography>
                    <Grid container display={'flex'} justifyContent={'space-between'}>
                      <Grid lg={6} xs={12} sx={{ pr: 1, mt: 1 }}>
                        <TextField
                          fullWidth
                          select
                          label='Mark Attendance'
                          size='small'
                          name='workshopAttendance'
                          value={values.workshopAttendance}
                          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                            const newValue = event.target.value as string;
                            setFieldValue('workshopAttendance', newValue);
                          }}>
                          <MenuItem value={'present'}>Present</MenuItem>
                          <MenuItem value={'absent'}>Absent</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid lg={6} md={12} xs={12} pr={1}>
                        {renderDateTimePicker(
                          'Attendance Marked At',
                          'attendanceMarkedAt',
                          values.attendanceMarkedAt ? dayjs(values.attendanceMarkedAt) : null,
                          setFieldValue,
                          'attendanceMarkedAt'
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      sx={{
                        width: '100%',
                        mt: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}>
                      <Typography mr={3} fontWeight={'bold'}>
                        Workshop Marked Completed At :
                      </Typography>
                      <Grid lg={6} md={12} xs={12} mr={1}>
                        {renderDateTimePicker(
                          'Select Date and Time',
                          'workshopMarkedCompletedAt',
                          values.workshopMarkedCompletedAt ? dayjs(values.workshopMarkedCompletedAt) : null,
                          setFieldValue,
                          'workshopMarkedCompletedAt'
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      sx={{
                        width: '100%',
                        mt: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}>
                      <Typography fontWeight={'bold'} sx={{ mt: 2, alignSelf: 'flex-start' }}>
                        Schedule Job Interview :
                      </Typography>
                      <Grid lg={6} md={12} xs={12} mr={1}>
                        <Accordion
                          sx={{ mb: 1, mt: 1, padding: '4px', fontSize: '0.875rem', lineHeight: '1.2' }}
                          variant='outlined'>
                          <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls='panel1-content'
                            id='panel1-heade'>
                            <Typography>{'Select Jobs'}</Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              maxHeight: 200,
                              overflowY: 'auto',
                              paddingRight: 2,
                            }}>
                            <List dense>
                              {Array.isArray(jobList) &&
                                jobList.map((job: IJobInfo) => (
                                  <ListItem
                                    key={job.id}
                                    secondaryAction={
                                      <Checkbox
                                        size='small'
                                        edge='end'
                                        onChange={() => handleCheckboxChange(job.id, job.title)}
                                        checked={selectedJobs.some((item) => item.jobId === job.id)}
                                        inputProps={{ 'aria-labelledby': `checkbox-list-secondary-label-${job.id}` }}
                                      />
                                    }
                                    disablePadding>
                                    <ListItemButton>
                                      <ListItemText
                                        id={`checkbox-list-secondary-label-${job.id}`}
                                        primary={job.title}
                                      />
                                    </ListItemButton>
                                  </ListItem>
                                ))}
                            </List>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    </Grid>
                    <Grid container display={'flex'} justifyContent={'space-between'}>
                      <FieldArray
                        name='scheduledJobInterviews'
                        render={() => (
                          <>
                            {selectedJobs.length > 0
                              ? selectedJobs.map((item, index) => [
                                  <Grid container key={index} spacing={1} alignItems='center' sx={{ mt: 1 }}>
                                    <Grid item lg={6} xs={12} pr={1}>
                                      <TextField
                                        size='small'
                                        fullWidth
                                        id={`scheduledJobInterviews[${index}].jobTitle`}
                                        label='Title'
                                        value={item.jobTitle}
                                        InputProps={{
                                          readOnly: true,
                                        }}
                                      />
                                    </Grid>
                                    <Grid item lg={6} xs={12} pr={1}>
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        localeText={{ okButtonLabel: 'Select' }}>
                                        <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                                          <DateTimePicker
                                            label='Scheduled At'
                                            slotProps={{
                                              textField: { size: 'small', error: false, fullWidth: true },
                                            }}
                                            name={`scheduledJobInterviews[${index}].interviewScheduledAt`}
                                            value={item.interviewScheduledAt ? dayjs(item.interviewScheduledAt) : null}
                                            viewRenderers={{
                                              hours: renderTimeViewClock,
                                              minutes: renderTimeViewClock,
                                              seconds: renderTimeViewClock,
                                            }}
                                            onChange={(
                                              date: string | number | Date | dayjs.Dayjs | null | undefined
                                            ) => {
                                              const formattedDate = dayjs(date)?.toISOString() || '';
                                              setFieldValue(
                                                `scheduledJobInterviews[${index}].interviewScheduledAt`,
                                                formattedDate
                                              );
                                              setFieldValue(`scheduledJobInterviews[${index}].jobId`, item.jobId);
                                            }}
                                          />
                                        </DemoContainer>
                                      </LocalizationProvider>
                                    </Grid>
                                  </Grid>,
                                ])
                              : null}
                          </>
                        )}
                      />
                    </Grid>
                    {selectedJobs.length > 0 ? (
                      <>
                        <Typography fontWeight={'bold'} sx={{ mt: 2 }}>
                          Employment Confirmation :
                        </Typography>
                        <Grid container display={'flex'} justifyContent={'space-between'}>
                          <Grid lg={6} xs={12} sx={{ pr: 1, mt: 1 }}>
                            <Autocomplete
                              fullWidth
                              size='small'
                              disableClearable
                              value={selectedConfirmedJob}
                              options={selectedJobs.map((item: ISelectedJob) => ({
                                label: item.jobTitle,
                                id: item.jobId,
                              }))}
                              getOptionLabel={(option: any) => option.label}
                              onChange={(event, object: any) => {
                                setFieldValue('confirmedJobId', object.id);
                                setSelectedConfirmedJob(object);
                              }}
                              renderInput={(params) => <TextField {...params} label='Confirm Employment' />}
                              isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                            />
                          </Grid>
                          <Grid lg={6} md={12} xs={12} pr={1}>
                            {renderDateTimePicker(
                              'Employment Start Date',
                              'employmentConfirmedAt',
                              values.employmentConfirmedAt ? dayjs(values.employmentConfirmedAt) : null,
                              setFieldValue,
                              'employmentConfirmedAt'
                            )}
                          </Grid>
                        </Grid>
                      </>
                    ) : null}
                    <Typography fontWeight={'bold'} sx={{ mt: 2 }}>
                      Update Client Employment Information :
                    </Typography>
                    <Grid container display={'flex'} justifyContent={'space-between'}>
                      <Grid lg={selectedEmplType.id === 5 ? 4 : 6} xs={12} sx={{ pr: 1, mt: 1 }}>
                        <Autocomplete
                          value={selectedEmplType}
                          disableClearable
                          size='small'
                          onChange={(event: any, newValue: any) => {
                            setSelectedEmplType(newValue);
                            setFieldValue('employmentType', newValue.label);
                          }}
                          options={employmentTypes.map((option: any) => ({
                            id: option.id,
                            label: option.title,
                          }))}
                          getOptionLabel={(option: any) => option.label}
                          renderInput={(params) => <TextField {...params} label='Employment Type' />}
                        />
                      </Grid>
                      {selectedEmplType.id === 5 ? (
                        <Grid lg={4} xs={12} sx={{ pr: 1, mt: 1 }}>
                          <TextField
                            fullWidth
                            size='small'
                            placeholder='Other (please specify)'
                            value={otherEmplType}
                            onChange={(event) => {
                              setOtherEmplType(event.target.value);
                              setFieldValue('employmentType', event.target.value);
                            }}
                          />
                        </Grid>
                      ) : null}
                      <Grid lg={selectedEmplType.id === 5 ? 4 : 6} xs={12} sx={{ pr: 1, mt: 1 }}>
                        <Autocomplete
                          value={selectedEmplStatus}
                          disableClearable
                          size='small'
                          onChange={(event: any, newValue: any) => {
                            setSelectedEmplStatus(newValue);
                            setFieldValue('employmentModuleStatus', newValue.label);
                          }}
                          options={employmentStatusOptions.map((option: any) => ({
                            id: option.id,
                            label: option.title,
                          }))}
                          getOptionLabel={(option: any) => option.label}
                          renderInput={(params) => <TextField {...params} label='Employment Status' />}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ mt: 2 }} justifyContent={'center'}>
                    <Grid item>
                      <LoadingButton
                        label={'Submit'}
                        onClick={() => handleSubmit()}
                        loading={isSubmitting}
                        size='medium'
                        type='submit'
                        variant='contained'
                        styles={{ textTransform: 'none' }}
                      />
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BackFillEmploymentData;
